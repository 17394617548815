<template>
	<div id="quotationSetting">
		<!-- 报价管理 -->
		<el-button icon="el-icon-plus" type="primary" @click="addCategory">新建类目</el-button>

		<el-table :data="detailedTreeListShow" row-key="id" stripe default-expand-all
			:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column label="类目/明细名称">
				<template slot-scope="scope">
					{{scope.row.detaiiledName}}
				</template>
			</el-table-column>
			<el-table-column label="单价">
				<template slot-scope="scope" v-if="scope.row.parentId != 0">
					￥{{scope.row.price?numberToCurrencyNo(scope.row.price):0}}
				</template>
			</el-table-column>
			<el-table-column label="所属机构">
				<template slot-scope="scope">
					{{scope.row.parentName}}
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button v-if="scope.row.parentId == 0" type="text" @click="handleAdd(scope.$index, scope.row)">
						新增明细
					</el-button>
					<el-divider direction="vertical" v-if="scope.row.parentId == 0"></el-divider>
					<el-button type="text" @click="handleEdit(scope.$index, scope.row)">
						编辑
					</el-button>
					<el-divider direction="vertical"></el-divider>
					<el-button type="text" @click="handleDelete(scope.$index, scope.row)">
						删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog :title="dialogTit" :visible.sync="dialogVisible">
			<el-form ref="form" :model="dialogForm" label-width="80px">
				<el-form-item label="所属机构" v-if="dialogForm.type === 0">
					<el-select v-model="dialogForm.deptId" clearable placeholder="请选择" v-if="dialogForm.type === 0">
						<el-option v-for="item in detailedTreeList" :key="item.id" :label="item.detaiiledName"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属类目" v-if="dialogForm.type === 1">
					<el-select v-model="dialogForm.parentId" clearable placeholder="请选择" v-if="dialogForm.type === 1">
						<el-option v-for="item in detailedTreeListShow" :key="item.id" :label="item.detaiiledName"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="类目名称" v-if="dialogForm.type === 0">
					<el-input v-model="dialogForm.detaiiledName" clearable placeholder="请输入类目名称"></el-input>
				</el-form-item>
				<el-form-item label="明细名称" v-if="dialogForm.type === 1">
					<el-input v-model="dialogForm.detaiiledName" clearable placeholder="请输入明细名称"></el-input>
				</el-form-item>
				<el-form-item label="价格" v-if="dialogForm.type === 1">
					<el-input-number v-model="dialogForm.price" :min="0" :step="50" label="请输入价格"></el-input-number>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleChange">取 消</el-button>
				<el-button type="primary" @click="handleSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		detailedTreeList, // 获取报价明细
		mechanismList, // 获取结构列表
		detailedAdd, //新增报价
		detailedUp, // 修改报价
		detailedRemove // 删除报价明细
	} from '@/request/apiPerCenter.js';
	export default {
		name: 'quotationSetting',
		components: {},
		data() {
			return {
				detailedTreeList: [],
				detailedTreeListShow: [],
				mechanismList: [],
				dialogTit: '新增报价明细',
				dialogForm: {
					type: 0, // 0：操作类目	1：操作明细
					parentId: '',
					deptId: '',
					detaiiledName: '',
					price: '',
					id: ''
				},
				dialogVisible: false
			}
		},
		created() {
			this.getdetailedTreeList();
		},
		mounted() {},
		methods: {
			// 新增类目
			addCategory() {
				// console.log('新增类目');
				this.dialogVisible = true;
				this.dialogForm.type = 0;
				this.dialogForm.parentId = '';
				this.dialogTit = '新增报价类目';
				// this.handleSubmit('add');
			},

			handleAdd(idx, row) {
				// console.log('新增明细');
				// console.log(JSON.parse(JSON.stringify(row)));
				this.dialogForm.type = 1;
				this.dialogForm.parentId = row.id;
				this.dialogForm.deptId = row.deptId;
				this.dialogVisible = true;
				this.dialogTit = '新增报价明细';
			},

			handleEdit(idx, row) {
				// console.log(JSON.parse(JSON.stringify(row)));
				this.dialogForm.id = row.id;
				if (row.parentId == '0') {
					this.dialogForm.type = 0;
					this.dialogTit = '修改报价类目';
					this.dialogForm.deptId = row.deptId;
					this.dialogForm.detaiiledName = row.detaiiledName;
				} else {
					this.dialogForm.type = 1;
					this.dialogTit = '修改报价明细';
					this.dialogForm.deptId = row.deptId;
					this.dialogForm.parentId = row.parentId;
					this.dialogForm.detaiiledName = row.detaiiledName;
					this.dialogForm.price = row.price;
				}
				this.dialogVisible = true;

			},

			handleDelete(idx, row) {
				this.$confirm('此操作将删除该节点, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					detailedRemove(row.id).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '提示',
								message: res.msg,
								position: 'bottom-right',
								type: 'success'
							});
							this.getdetailedTreeList();
						} else {
							this.$notify({
								title: '提示',
								message: res.msg,
								position: 'bottom-right',
								type: 'error'
							});
						}
					})
				});

			},

			handleSubmit() {
				let data = {};
				if (this.dialogTit == '新增报价类目') {
					data.parentId = 0;
					data.deptId = this.dialogForm.deptId;
					data.detaiiledName = this.dialogForm.detaiiledName;
					this.detailedAddFun(data)
				} else if (this.dialogTit == '新增报价明细') {
					data.parentId = this.dialogForm.parentId;
					data.deptId = this.dialogForm.deptId;
					data.detaiiledName = this.dialogForm.detaiiledName;
					data.price = this.dialogForm.price;
					this.detailedAddFun(data)
				} else if (this.dialogTit == '修改报价类目') {
					data.parentId = 0;
					data.id = this.dialogForm.id;
					data.deptId = this.dialogForm.deptId;
					data.detaiiledName = this.dialogForm.detaiiledName;
					this.detailedUpdataFun(data)
				} else if (this.dialogTit == '修改报价明细') {
					data.id = this.dialogForm.id;
					data.parentId = this.dialogForm.parentId;
					data.deptId = this.dialogForm.deptId;
					data.detaiiledName = this.dialogForm.detaiiledName;
					data.price = this.dialogForm.price;
					this.detailedUpdataFun(data)
				}
				console.log(data)
				this.dialogVisibleChange()

			},

			detailedAddFun(data) {
				detailedAdd(data).then(res => {
					if (res.code == 200) {
						this.dialogVisible = false;
						this.getdetailedTreeList();
					} else {
						this.$notify({
							title: '提示',
							message: res.msg,
							position: 'bottom-right',
							type: 'error'
						});
					}
				})
			},

			detailedUpdataFun(data) {
				detailedUp(data).then(res => {
					if (res.code == 200) {
						this.dialogVisible = false;
						this.getdetailedTreeList();
					} else {
						this.$notify({
							title: '提示',
							message: res.msg,
							position: 'bottom-right',
							type: 'error'
						});
					}
				})
			},

			dialogVisibleChange() {
				this.dialogVisible = false;
				this.dialogForm = {
					type: '', // 0：操作类目	1：操作明细
					parentId: '',
					deptId: '',
					detaiiledName: '',
					price: '',
					id: ''
				}
				this.getdetailedTreeList();
			},

			// 获取报价明细列表
			getdetailedTreeList() {
				detailedTreeList().then(res => {
					let data = res.rows
					data.map(item => item.children)
					if (res.code == 200) {
						this.detailedTreeList = data
						this.detailedTreeListShow = data.map(item => item.children).flat();
					}
				})
			},

			// 获取结构列表
			getmechanismList() {
				mechanismList().then(res => {
					if (res.code == 200) {
						this.mechanismList = res.rows
					}
				})
			},

			// 千位符
			numberToCurrencyNo(value) {
				if (!value) return 0
				// 获取整数部分
				const intPart = Math.trunc(value)
				// 整数部分处理，增加,
				const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
				// 预定义小数部分
				let floatPart = ''
				// 将数值截取为小数部分和整数部分
				const valueArray = value.toString().split('.')
				if (valueArray.length === 2) { // 有小数部分
					floatPart = valueArray[1].toString() // 取得小数部分
					return intPartFormat + '.' + floatPart
				}
				return intPartFormat + floatPart
			}
		}
	}
</script>

<style lang="less" scoped>
	#quotationSetting {
		padding: 30px;
		background-color: #fff;

		/deep/.el-dialog {
			width: 400px;

			.el-form {

				.el-select,
				.el-inpit {
					width: 100%;
				}
			}
		}
	}
</style>
<template>
	<div id="projectSetting">
		<!-- 项目管理 -->
		<el-table :data="itemList" row-key="id" default-expand-all stripe
			:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column label="部门/项目名称">
				<template slot-scope="scope">
					{{scope.row.itemName}}
				</template>
			</el-table-column>
			<el-table-column label="所属部门">
				<template slot-scope="scope" v-if="scope.row.parentId">
					{{scope.row.parentName}}
				</template>
			</el-table-column>
			<el-table-column label="操作" width="100">
				<template slot-scope="scope">
					<el-button v-if="!scope.row.parentId" type="text" @click="handleAdd(scope.$index, scope.row)">
						新增项目
					</el-button>
					<el-button v-else type="text" @click="handleEdit(scope.$index, scope.row)">
						编辑
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog :title="dialogTit" :visible.sync="dialogVisible">
			<el-form ref="form" :model="itemform" label-width="80px">
				<el-form-item label="所属机构">
					<el-select v-model="itemform.parentId" clearable placeholder="请选择" disabled>
						<el-option v-for="item in itemList" :key="item.id" :label="item.itemName" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="项目名称">
					<el-input v-model="itemform.itemName" clearable placeholder="请输入项目名"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleChange">取 消</el-button>
				<el-button type="primary" @click="handleSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		itemList, // 获取项目列表
		addItem, // 新增项目
		updateItem // 修改项目
	} from '@/request/apiPerCenter.js';
	export default {
		name: 'projectSetting',
		components: {},
		data() {
			return {
				itemList: [],
				itemform: {
					id: '',
					itemName: '',
					parentId: '',
					status: '0'
				},
				dialogTit: '新增项目',
				dialogVisible: false,
			}
		},
		created() {
			this.getItemList();
		},
		mounted() {},
		methods: {
			handleAdd(idx, row) {
				this.dialogTit = '新增项目'
				this.itemform.parentId = row.id
				this.dialogVisible = true
			},

			handleEdit(idx, row) {
				this.dialogTit = '修改项目'
				this.itemform = row;
				this.dialogVisible = true
			},

			handleSubmit() {
				// if (!this.itemform.itemName) {
				// 	this.itemform.itemName = "未命名项目"
				// }
				if (this.dialogTit == '新增项目') {
					addItem(this.itemform).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '成功',
								message: '新增项目成功',
								position: 'bottom-right',
								type: 'success'
							});
							this.dialogVisibleChange();
						} else {
							this.$notify({
								title: '提示',
								message: res.msg,
								position: 'bottom-right',
								type: 'error'
							});
						}
					})
				} else {
					updateItem(this.itemform).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '成功',
								message: '修改项目成功',
								position: 'bottom-right',
								type: 'success'
							});
							this.dialogVisibleChange();
						} else {
							this.$notify({
								title: '提示',
								message: res.msg,
								position: 'bottom-right',
								type: 'error'
							});
						}
					})
				}

			},

			dialogVisibleChange() {
				this.dialogVisible = false;
				this.itemform = {
					id: '',
					itemName: '',
					parentId: '',
					status: '0'
				}
				this.getItemList();
			},

			getItemList() {
				itemList().then(res => {
					if (res.code == 200) {
						this.itemList = res.rows;
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	#projectSetting {
		padding: 30px;
		background-color: #fff;

		/deep/.el-dialog {
			width: 400px;

			.el-form {

				.el-select,
				.el-inpit {
					width: 100%;
				}
			}
		}
	}
</style>
<template>
	<div id="ChangeInfo">
		<!-- 修改个人信息 -->
		<div class="userInfo">
			<el-upload class="avatar-uploader" :action="`${baseUrl}/spider/web/uploadAvatar`" :show-file-list="false"
				:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
				:headers="{Authorization:`Bearer ${Authorization}`}">
				<el-image v-if="userInfo.avatar" :src="userInfo.avatar" class="avatar" fit="cover"></el-image>
				<i v-else class="el-icon-user-solid avatar-uploader-icon"></i>
				<i class="el-icon-camera-solid"></i>
			</el-upload>

			<div class="info">
				<div class="userName">
					<span>用户名：</span>
					{{userInfo.nickName}}
				</div>
				<div class="pwd" v-if="!type">
					<span>密码：</span>
					********
					<el-button size="mini" @click="type = !type">修改密码</el-button>
				</div>
				<div class="pwd" v-if="type">
					<span>新密码：</span>
					<el-input placeholder="请输入新密码,限制为大于6位小于18位字符" maxlength="18" v-model="pwd" show-password
						clearable></el-input>
				</div>
				<div class="pwd" v-if="type">
					<span>确认密码：</span>
					<el-input placeholder="二次确认密码" maxlength="18" v-model="pwd2" show-password clearable></el-input>
				</div>
				<div class="opt" v-if="type">
					<span></span>
					<el-button size="mini" @click="type = !type">取消</el-button>
					<el-button size="mini" @click="updatePwdFun()">提交</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		UserInfo, // 获取用户信息 
		updatePwd
	} from '@/request/apiPerCenter.js';
	import baseUrl from "@/request/config.js"
	export default {
		name: 'ChangeInfo',
		components: {},
		data() {
			return {
				baseUrl: '',
				imageUrl: '',
				type: false, // 修改密码状态
				pwd: '',
				pwd2: '',
				userInfo: "",
				Authorization: ''
			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.Authorization = localStorage.getItem('headToken');
			this.getUserInfo()
		},
		mounted() {},
		methods: {
			// 修改密码
			updatePwdFun() {
				var data = {
					newPassword: this.pwd
				}
				if (!this.pwd || !this.pwd2) {
					this.$alert('请输入密码和二次确认密码。', '提示', {
						confirmButtonText: '确定',
						type: "error",
						closeOnClickModal: true,
						closeOnPressEscape: true
					});
				} else if (this.pwd.length < 6 || this.pwd.length > 18) {
					this.$alert('请输入大于6位 小于18位的密码', '提示', {
						confirmButtonText: '确定',
						type: "error",
						closeOnClickModal: true,
						closeOnPressEscape: true
					});
				} else if (this.pwd !== this.pwd2) {
					this.$alert('两次输入密码不一致', '提示', {
						confirmButtonText: '确定',
						type: "error",
						closeOnClickModal: true,
						closeOnPressEscape: true
					});
				} else {
					updatePwd(data).then(res => {
						if (res.code == 200) {
							this.$alert('修改密码成功', '提示', {
								confirmButtonText: '确定',
								type: "success",
								closeOnClickModal: true,
								closeOnPressEscape: true
							});
						} else {
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定',
								type: "error",
								closeOnClickModal: true,
								closeOnPressEscape: true
							});
						}
					})
				}

			},

			handleAvatarSuccess() {
				// this.getUserInfo()
				this.$router.go(0)
			},
			
			beforeAvatarUpload(file) {
				const isJPG = file.type.split('/')[0] === 'image';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像只能是图片');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}

				return isJPG && isLt2M;
			},

			getUserInfo() {
				UserInfo().then(res => {
					if (res.code == 200) {
						this.userInfo = res.data.user;
					}
				}).catch(err => {
					console.log(err)
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	#ChangeInfo {
		.title {
			font-size: 14px;
			font-weight: 500;
			color: #181B1A;
		}

		.userInfo {
			padding: 30px;
			display: flex;
			flex-direction: row;
			align-items: center;
			background-color: #fff;
			margin-top: 20px;
			border-radius: 20px;

			.avatar-uploader {
				margin: 20px 40px 20px 0;
				background-color: #fff;
				border-radius: 50%;
				// overflow: hidden;
				box-shadow: 0 0 20px rgba(24, 27, 26, 0.1);
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;

				.el-icon-camera-solid {
					position: absolute;
					right: 0;
					bottom: 0;
					font-size: 20px;
					padding: 10px;
					border-radius: 15px;
					background-color: #F4F4F4;
					border: 3px solid #fff;
				}

				.el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}

				.el-upload:hover {
					border-color: #409EFF;
				}

				.avatar-uploader-icon {
					font-size: 48px;
					color: #f4f4f4;
					width: 150px;
					height: 150px;
					line-height: 150px;
					text-align: center;

				}

				.avatar {
					width: 150px;
					height: 150px;
					display: block;
					border-radius: 50%;
				}
			}


			.info {
				display: flex;
				flex: 1;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				max-width: 600px;

				.userName,
				.pwd,
				.opt {
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size: 14px;
					margin-bottom: 20px;

					&:last-child {
						margin-bottom: 0;
					}

					span {
						width: 100px;
						text-align: right;
					}
				}

				.pwd .el-button {
					margin-left: 20px;
					background-color: #fff;
					color: #682EDC;
					border: 1px solid #682EDC;
				}
			}
		}
	}
</style>
<template>
	<div id="DocumentList">
		<!-- 文档管理 -->
		<div class="searthBox">
			<div class="item">
				<el-input placeholder="请输入关键词进行文档搜索" v-model="searth" class="input-with-select" @change="getManageList"
					clearable>
					<el-button slot="append" icon="el-icon-search"></el-button>
				</el-input>
			</div>
			<div class="item">
				<el-cascader v-model="mechanismVal" :options="mechanismList" placeholder="请选择机构"
					:props="{ expandTrigger: 'hover',label:'deptName',value:'deptId' }" @change="getManageList"
					:show-all-levels="false" clearable>
				</el-cascader>
			</div>
			<div class="item">
				<el-select v-model="dateVal" placeholder="请选择创建时间" @change="getManageList" filterable clearable>
					<el-option v-for="item in dateType" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="item">
				<el-select v-model="tagVal" placeholder="文档类型" @change="getManageList" filterable clearable>
					<el-option v-for="item in tagsList" :key="item.id" :label="item.tagName"
						:value="item.id"></el-option>
				</el-select>
			</div>
			<div class="item">
				<el-cascader v-model="itemVal" :options="itemList" placeholder="请选择项目"
					:props="{ expandTrigger: 'hover',label:'itemName',value:'id' }" @change="getManageList"
					:show-all-levels="false" clearable>
				</el-cascader>
			</div>
		</div>
		<el-table :data="deskList" stripe style="width: 100%">
			<el-table-column prop="title" label="文档名称" min-width="300">
			</el-table-column>
			<el-table-column prop="createBy" label="创建人">
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间">
			</el-table-column>
			<el-table-column prop="fileType" label="文档类型">
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button size="mini" type="primary" @click="handleLook(scope.$index, scope.row)"
						icon="el-icon-view" circle></el-button>
					<el-button size="mini" type="info" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit"
						circle></el-button>
					<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)"
						icon="el-icon-delete-solid" circle></el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next" :total="total" :hide-on-single-page="true"
			@current-change="handleCurrentChange"></el-pagination>

		<el-dialog :custom-class="rowData.fileType == '图片'?'mainDialog':'mainDialog min'" :visible.sync="mainDialog">
			<div class="listBox">
				<img :src="rowData.imagePath" alt="" v-if="rowData.fileType == '图片'">
				<div class="iconshow" v-else-if="rowData.fileType == '文档'">
					<i class="icon Excel" v-if="rowData.fileSuffix == 'xls' ||rowData.fileSuffix ==  'xlsx'"></i>
					<i class="icon pdf " v-else-if="rowData.fileSuffix == 'pdf'"></i>
					<i class="icon ppt" v-else-if="rowData.fileSuffix == 'ppt' ||rowData.fileSuffix ==  'pptx'"></i>
					<i class="icon word" v-else-if="rowData.fileSuffix == 'doc' ||rowData.fileSuffix ==  'docx'"></i>
					<i class="icon yasuobao" v-else-if="rowData.fileSuffix == 'zip' ||rowData.fileSuffix ==  'rar'"></i>
					<i class="icon wendang" v-else></i>
				</div>
				<div class="iconshow" v-else-if="'视频'"><i class="icon shipin"></i></div>
				<div class="iconshow" v-else><i class="icon wendang"></i></div>
			</div>
			<div class="infoBox">
				<div class="tit">
					<span>{{rowData.title}}</span>
					<p class="time">{{rowData.createTime }}</p>
				</div>
				<ul class="cont">
					<li><span>作品分类</span><b>{{rowData.fileType}}</b> </li>
					<li><span>作品规格</span><b v-if="rowData.imageSize">{{rowData.imageSize}}</b> <b
							v-else>{{rowData.fileSuffix}}</b> </li>
					<li><span>关键词</span><b><el-tag v-for="(item,i) in rowData.tags" :key="i">{{item}}</el-tag></b> </li>
				</ul>
				<div class="opts">
					<!-- <el-button> <i class="iconfont icon-xiugai1"></i>修改</el-button> -->
					<el-button type="info" @click="dowloadImg()" v-if="rowData.fileType=='图片'||rowData.fileType=='视频'">
						<i class="iconfont icon-xiazaitu"></i>预览
					</el-button>
					<el-button type="info" @click="dowloadImg()" v-else>
						<i class="iconfont icon-xiazaitu"></i>下载
					</el-button>

					<el-popover v-if="rowData.filePath" popper-class="netDiskPop" placement="bottom" title="下载源文件"
						trigger="hover">
						<el-button type="primary" slot="reference">
							<i class="iconfont icon-xiazai"></i>
							下载源文件
						</el-button>
						<a :href="rowData.filePath" target="_blank"
							style="display: block; color: blueviolet; font-size: 14px; margin-top: 10px;">网盘地址：{{rowData.filePath}}</a>
						<p v-if="rowData.filePsd" style="margin-top: 6px; font-size: 14px;">
							提取密码：{{rowData.filePsd}}
							<el-button size="mini" style="padding: 4px 10px; margin-left: 5px;"
								@click="copyPsd(rowData.filePsd)">复制密码</el-button>
						</p>
					</el-popover>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		manageList, // 查询自己上传的网盘文件
		fileNetdiskRemove // 删除已上传的文档
	} from '@/request/apiPerCenter.js';
	import {
		tagTreeList, // 服务类型列表
		itemList, // 获取项目列表
		mechanismList, //机构列表   
		fileNetdisk //获取网盘内容详情
	} from '@/request/apiNetDisk.js';
	export default {
		name: 'DocumentList',
		data() {
			return {
				deskList: [],
				pageSize: 10,
				pageNum: 1,
				total: 0,
				mainDialog: false,
				rowData: '',
				searth: '', // 搜索关键词
				tagsList: [], // 类型列表
				tagVal: '',
				mechanismList: [], // 机构列表
				mechanismVal: '',
				itemList: [], // 项目列表
				itemVal: '',
				dateType: [{ // 按时间查找
					value: 'WEEK',
					label: '最近一周'
				}, {
					value: 'MONTH',
					label: '最近一月'
				}, {
					value: 'QUART',
					label: '最近一季度'
				}, {
					value: 'YEAR',
					label: '最近一年'
				}],
				dateVal: '',
			}
		},
		created() {
			this.geTmechanismList();
			this.getitemList();
			this.getTagTreeList();
			this.getManageList()
		},
		methods: {
			handleEdit(index, row) {
				console.log(index, row);
				this.$router.push({
					name: 'Staging',
					query: {
						id: row.id,
						form: 'DocumentList'
					}
				})
			},
			handleDelete(index, row) {
				console.log(index, row);
				this.$confirm('此操作将删除该文档, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					fileNetdiskRemove(row.id).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '提示',
								message: `删除成功`,
								type: 'success',
								position: 'bottom-right'
							});
							this.getManageList()
						}
					})
				})

			},
			handleLook(index, row) {
				fileNetdisk(row.id).then(res => {
					if (res.code == 200) {
						this.mainDialog = true;
						// if (res.data.tags) {
						// 	res.data.tags = res.data.tags.split(",");
						// }
						this.rowData = res.data;
					}
				})
			},

			tagSelectFun(item) {
				if (this.tagVal != item && item) {
					this.tagVal = item;
				}
				this.fastSelectFun();
			},

			fastSelectFun() {
				this.pageNum = 1;
				this.imgData = [];
				this.lodingType = 'show';
				setTimeout(() => {
					this.getManageList();
				}, 300)
			},

			// 机构列表查询
			geTmechanismList() {
				mechanismList().then(res => {
					if (res.code == 200) {
						this.mechanismList = res.rows;
					}
				})
			},

			// 获取项目列表
			getitemList() {
				itemList().then(res => {
					if (res.code == 200) {
						this.itemList = res.rows
					}
				})
			},

			// 获取网盘筛选列表
			getTagTreeList() {
				tagTreeList().then(res => {
					if (res.code == 200) {
						this.tagsList = res.rows
					}
				})
			},

			//下载图片
			dowloadImg() {
				window.open(this.rowData.imagePath, '_blank')
			},

			// 打开网盘
			copyPsd(psd) {
				console.log(psd)
				const save = function(e) {
					e.clipboardData.setData('text/plain', psd)
					e.preventDefault() // 阻止默认行为
				}
				document.addEventListener('copy', save) // 添加一个copy事件
				document.execCommand('copy') // 执行copy方法
				this.$message({
					message: '复制成功',
					type: 'success'
				})
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.getManageList();
			},
			getManageList() {
				let data = {}
				data.pageSize = this.pageSize;
				data.pageNum = this.pageNum;
				data.tagIds = this.tagVal;
				data.title = this.searth;
				data.itemId = this.itemVal;
				data.mechanismId = this.mechanismVal;
				data.dateType = this.dateVal;
				data.isAsc = 'desc';
				data.orderByColumn = 'create_time';
				manageList(data).then(res => {
					if (res.code == 200) {
						this.deskList = res.rows;
						this.total = res.total
					}
				}).catch(err => {
					console.log(err)
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	#DocumentList {
		padding: 50px;
		background-color: #fff;

		/deep/.searthBox {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			// flex-wrap: wrap;

			.item {
				margin: 0 10px 20px;
				flex: 1;

				&:last-child {
					margin-right: 0;
				}

				&:first-child {
					margin-left: 0;
				}

				.el-input,
				.el-select,
				.el-cascader {
					width: 100%;
				}
			}
		}

		.el-pagination {
			margin-top: 40px;
			text-align: center;
		}


		/deep/.mainDialog {
			max-width: 1100px;
			border-radius: 20px;

			.el-dialog__header {
				padding: 0;
			}

			.el-dialog__body {
				display: flex;
				flex-direction: row;
				padding: 40px;

				.listBox {
					flex: 1;

					img {
						display: block;
						width: 100%;
						border-radius: 30px;
					}

					.iconshow {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;

						i {
							font-size: 140px;
						}
					}
				}

				.infoBox {
					width: 300px;
					margin-left: 40px;

					.tit {
						display: flex;
						flex-direction: column;
						padding-bottom: 16px;
						border-bottom: 1px solid #F2F2F2;
						margin-bottom: 16px;

						span {
							font-size: 18px;
							color: #181B1A;
							overflow: hidden; //超出的文本隐藏
							display: -webkit-box;
							-webkit-line-clamp: 2; // 超出多少行
							-webkit-box-orient: vertical;
						}

						.time {
							font-size: 12px;
							color: rgba(24, 27, 26, 0.6);
						}
					}

					.cont {
						display: flex;
						flex-direction: column;
						margin-bottom: 10px;

						li {
							display: flex;
							flex-direction: row;
							align-items: center;
							margin-bottom: 10px;

							>span {
								width: 50px;
								text-align: right;
								margin-right: 10px;
								color: rgba(24, 27, 26, 0.6);
							}

							b {
								flex: 1;
								color: rgba(24, 27, 26, 0.8);

								.el-tag {
									line-height: 1;
									color: rgba(24, 27, 26, 0.8);
									height: auto;
									background-color: #fff;
									border: 1px solid #181B1A;
									padding: 2px 3px;
									margin-right: 5px;
								}
							}
						}
					}

					.opts {
						display: flex;
						flex-wrap: wrap;

						.el-button {
							height: auto;
							background-color: #fff;
							color: #181B1A;
							border: none;
							font-size: 14px;
							padding: 10px;
							border: 1px solid #181B1A;
							margin: 0 10px 0 0;
						}

						.el-button--info {
							border: 1px solid #682EDC;
							color: #682EDC;
						}

						.el-button--primary {
							border: 1px solid #682EDC;
							color: #fff;
							background: #682EDC;
						}
					}
				}
			}
		}

		/deep/.mainDialog.min {
			max-width: 600px;
		}

	}
</style>
<template>
	<div id="PreCenter">
		<!-- 个人中心 -->
		<el-tabs v-model="activeName" lazy>
			<el-tab-pane name="PreCenter">
				<template slot="label">
					<i class="icon gerenzhongxin"></i>
					个人中心
				</template>
			</el-tab-pane>
			<el-tab-pane name="departmentSetting" v-if="UserInfo.isManage">
				<template slot="label">
					<i class="icon bumenguanli"></i>
					部门管理
				</template>
			</el-tab-pane>
			<el-tab-pane name="projectSetting" v-if="UserInfo.isManage">
				<template slot="label">
					<i class="icon xiangmuguanli"></i>
					项目管理
				</template>
			</el-tab-pane>
			<el-tab-pane name="fourth" v-if="UserInfo.isManage">
				<template slot="label">
					<i class="icon baojiaguanli"></i>
					报价明细管理
				</template>
			</el-tab-pane>
			<el-tab-pane name="DocumentList">
				<template slot="label">
					<i class="icon wendangguanli"></i>
					我的网盘
				</template>
			</el-tab-pane>
		</el-tabs>
		<PreCent v-if="activeName == 'PreCenter'" />
		<departmentSetting v-if="activeName == 'departmentSetting'" />
		<projectSetting v-if="activeName == 'projectSetting'" />
		<quotationSetting v-if="activeName == 'fourth'" />
		<DocumentList v-if="activeName == 'DocumentList'" />
	</div>
</template>

<script>
	import {
		UserInfo, // 获取用户信息
	} from '@/request/apiPerCenter.js';
	import PreCent from './ChangeInfo/ChangeInfo.vue';
	import departmentSetting from './departmentSetting/departmentSetting.vue';
	import projectSetting from './projectSetting/projectSetting.vue';
	import quotationSetting from './quotationSetting/quotationSetting.vue';
	import DocumentList from './DocumentList/DocumentList.vue';
	export default {
		name: 'PreCenter',
		components: {
			PreCent, // 个人中心
			departmentSetting, // 机构管理
			projectSetting, // 项目管理
			quotationSetting, // 报价管理
			DocumentList // 文档管理
		},
		data() {
			return {
				activeName: 'PreCenter',
				UserInfo: ''
			}
		},
		created() {
			this.getUserInfo();
		},
		mounted() {},
		methods: {
			getUserInfo() {
				UserInfo().then(res => {
					if (res.code == 200) {
						this.UserInfo = res.data.user
					}
				})
			},

			handleClick(tab, event) {
				console.log(tab, event);
			}
		}
	}
</script>

<style lang="less" scoped>
	#PreCenter {
		padding: 40px;
		background: #F4F4F4;
		min-height: calc(100% - 100px);
	}
</style>
<template>
	<div id="departmentSetting">
		<div class="bumenBox">
			<div class="opt">
				<el-button icon="el-icon-plus" type="primary" @click="handleAdd">新增部门</el-button>
			</div>

			<el-table :data="mechanismList" row-key="deptId" default-expand-all stripe
				:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
				<el-table-column label="部门名称">
					<template slot-scope="scope">
						<span>{{scope.row.deptName}}</span>
					</template>
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<el-switch v-model=" scope.row.status" active-text="启用" inactive-text="停用"
							active-color="#13ce66" inactive-color="#ff4949" active-value="0" inactive-value="1"
							@change="switchChange(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="handleEdit(scope.$index, scope.row)">
							编辑
						</el-button>
						<el-divider direction="vertical"></el-divider>
						<el-button type="text" @click="handleAdd(scope.$index, scope.row)">
							新增
						</el-button>
						<el-divider direction="vertical" v-if="scope.$index!=0"></el-divider>
						<el-button type="text" v-if="scope.$index!=0" @click="handleDelete(scope.$index, scope.row)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog :title="dialogTit" :visible.sync="dialogVisible">
			<el-form ref="form" :model="dialogForm" label-width="80px">
				<el-form-item label="上级部门">
					<el-cascader :disabled="false" v-model="dialogForm.parentId" :options="mechanismList"
						placeholder="请选择上级部门" clearable
						:props="{ expandTrigger: 'hover',label:'deptName',value:'deptId', checkStrictly : true }">
					</el-cascader>
				</el-form-item>
				<el-form-item label="部门名称">
					<el-input v-model="dialogForm.deptName"></el-input>
				</el-form-item>
				<el-form-item label="部门状态">
					<el-switch v-model="dialogForm.status" active-text="启用" inactive-text="停用" active-color="#13ce66"
						inactive-color="#ff4949" active-value="0" inactive-value="1">
					</el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		deptManageTreeList, // 机构列表查询 
		mechanismUpdate, // 修改机构信息
		mechanismDelete, // 删除机构信息
		mechanismAdd, // 新增部门

	} from '@/request/apiPerCenter.js';
	export default {
		name: 'departmentSetting',
		components: {},
		data() {
			return {
				jigouTit: '生命绿洲公益服务中心',
				mechanismList: [],
				dialogVisible: false,
				dialogTit: '新增部门',
				dialogForm: {
					parentId: '', // 上级部门
					deptName: '', // 部门名称
					status: "0" // 部门状态 0：启用   1：停用
				}
			}
		},
		created() {
			this.geTmechanismList();
		},
		mounted() {},
		methods: {
			// 部门状态修改
			switchChange(row) {
				console.log(row)
				let data = {
					deptId: row.deptId,
					status: row.status
				}
				mechanismUpdate(data).then(res => {
					if (res.code == 200) {
						this.$notify({
							title: '成功',
							message: '机构/部门状态修改成功。',
							position: 'bottom-right',
							type: 'success'
						});
						this.geTmechanismList()
					}
				})
			},

			// 机构列表查询
			geTmechanismList() {
				deptManageTreeList().then(res => {
					if (res.code == 200) {
						this.mechanismList = res.rows;
					}
				})
			},

			// 新增确认
			handleSubmit() {
				if (!this.dialogForm.deptName) {
					this.$notify({
						title: '提示',
						message: '请输入部门名称',
						position: 'bottom-right',
						type: 'error'
					});
				} else {
					let data = this.dialogForm;
					console.log(data);
					data.parentId = data.parentId.toString();
					console.log(data);

					if (this.dialogTit == '新增部门') {
						mechanismAdd(data).then(res => {
							if (res.code == 200) {
								this.$notify({
									title: '提示',
									message: `${this.dialogTit}成功`,
									position: 'bottom-right',
									type: 'success'
								});
								this.dialogVisible = false;
								this.geTmechanismList()
							} else {
								this.$notify({
									title: '提示',
									message: `${res.msg}`,
									position: 'bottom-right',
									type: 'error'
								});
							}
						})
					} else {
						mechanismUpdate(data).then(res => {
							if (res.code == 200) {
								this.$notify({
									title: '提示',
									message: '部门修改成功。',
									position: 'bottom-right',
									type: 'success'
								});
								this.dialogVisible = false;
								this.geTmechanismList()
							}
						})
					}
					this.dialogForm = {
						parentId: '', // 上级部门
						deptName: '', // 部门名称
						status: "0" // 部门状态 0：启用   1：停用
					};
				}
			},

			handleAdd(index, row) {
				this.dialogTit = '新增部门';
				if (row) {
					this.dialogForm.parentId = row.deptId;
				} else {
					this.dialogForm.parentId = 100;
				}
				this.dialogVisible = true;
			},

			handleEdit(index, row) {
				console.log(index, row);
				this.dialogTit = '修改部门';
				this.dialogForm.parentId = row.parentId;
				this.dialogForm.deptName = row.deptName;
				this.dialogForm.deptId = row.deptId;
				this.dialogForm.status = row.status;
				this.dialogVisible = true;
			},

			handleDelete(index, row) {
				console.log(index, row);
				let data = row.deptId
				this.$confirm('确定要删除么?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					mechanismDelete(data).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '删除成功',
								message: '删除成功',
								position: 'bottom-right',
								type: 'success'
							});
							this.geTmechanismList();
						} else {
							this.$notify({
								title: '删除失败',
								message: res.msg,
								position: 'bottom-right',
								type: 'error'
							});
						}
					})
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	#departmentSetting {
		.title {
			font-size: 14px;
			font-weight: 500;
			color: #181B1A;
		}

		.jigouBox {
			padding: 30px;
			background-color: #fff;
			margin-top: 20px;
			border-radius: 20px;

			.tit {
				font-size: 20px;
				color: #181B1A;
			}

			.cont {
				display: flex;
				margin-top: 20px;

				span {
					flex: 1;
					font-size: 14px;
					height: 40px;
					background-color: #f4f4f4;
					line-height: 40px;
					border-radius: 4px;
					max-width: 700px;
					padding: 0 20px
				}

				.el-input {
					flex: 1;
					max-width: 700px;
				}

				.el-button {
					margin-left: 20px;
					background-color: #682EDC;
					color: #fff;
				}
			}
		}

		.bumenBox {
			padding: 30px;
			background-color: #fff;
			margin-top: 20px;
			border-radius: 20px;

			.opt {
				margin-bottom: 20px;

				// .el-button {
				// 	background-color: #682EDC;
				// 	color: #fff;
				// }
			}
		}

		/deep/.el-dialog {
			width: 400px;

			.el-form {

				.el-cascader,
				.el-inpit {
					width: 100%;
				}
			}
		}
	}
</style>